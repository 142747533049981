import styled from 'styled-components'
import { Link } from "gatsby"

const Details = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-flow: row wrap;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
`

const Blocks = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    place-self: flex-start;
    margin-top: -30px;
    width: 80%;
    z-index: 2;
    @media (max-width: 768px) {
        flex-flow: column nowrap;
    }
`;

const Block = styled.div`
    font-size: 22px;
    color: #fff;
    background-color: #202020;
    padding: 50px;
    width: 32%;
    margin: 5px;
    @media (max-width: 768px) {
        width: 100%;
        padding: 40px;
    }
`;

const List = styled.ul`
list-style-type: none;  
`;

const ListItem = styled.li`
    text-align: left;
    margin-right: 40px;
    padding-left: 15px;
`;

const Pic = styled.img`
    margin: 0;
`;

const Label = styled.div`
    width: 80%;
    font-weight: bold;
    text-align: left;
    font-size: 24px;
`;

const SponsorGoTo = styled(Link)`
    font-size: 24px;
    border: solid thin #000;
    color: #fff;
    background-color: #000;
    text-decoration: none;
    padding: 15px;
    margin-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: "Didot LT Pro", georgia, serif";
    :hover {
        cursor: pointer;
    }
`;



export {
    Blocks,
    Block,
    Details,
    Pic,
    Label,
    SponsorGoTo,
    List,
    ListItem,
}