import styled from "styled-components";
import {Input} from "antd";

const { TextArea } = Input;

const ContactView = styled.div`
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #f7eee4;
    font-family: 'Raleway', sans-serif;
    color: #707070;
    max-height: 645px;
    padding-top: 35px;
    padding-bottom: 25px;
`;

const InfoBox = styled.input`
    width: 50vw;
    height: 75px;
    margin: 15px;
    padding-left: 10px;
    background-color: #f7eee4;
    border: solid #707070;
    border-width: thin;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #707070;
    }
    @media (max-width: 768px) {
        width: 70vw;
        height: 10vh;
        margin: 2%;
      }
`;

const InfoCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-width: thin;
    
`;

const Message = styled.textarea`
    width: 50vw;
    height: 89%;
    margin: 10px;
    padding-left: 10px;
    padding-top: 10px;
    border-width: thin;
    background-color: #fff;
    border: solid #707070;
    border-width: thin;
    :focus {
        outline: none;
    }
    ::placeholder {
        color: #707070;
    }
    @media (max-width: 768px) {
        width: 70vw;
        height: 100%;
        margin: 2%;
      }
`;

const Even = styled.div`
    margin-left: 35px;
    margin-right: 35px;
    font-weight: bold;
    font-size: 18px;
    @media (max-width: 768px) {
        width: 100%;
        margin: 2%;
      }
    @media (max-width: 425px) {
        text-align: center;

    }
`;

const ContactTitle = styled.div`
    margin: 10px;
    font-weight: bold;
    font-size: 28px;
    font-family: 'Raleway',sans-serif;
`;


const ContactBtn = styled.div`
height: 85%;
margin-top: 15px;
background-color: #707070;
font-weight: bold;
font-size: 20px;
border: none;
color: #fff;
width: 100%;
/* text-align: center; */
display: flex;
align-items: center;
justify-content: center;
    :focus {
        outline: -webkit-focus-ring-color auto 0px;
    }
    @media (max-width: 768px) {
        width: 65.5%;
        font-size: 12px;
        margin: 2%;
      }
      :hover {
          cursor: pointer;
      }
`;

const Info = styled.div`
    display: flex;
    font-family: 'Raleway',sans-serif;
    letter-spacing: .5px;
    @media (max-width: 768px) {
        width: -webkit-fill-available;
        flex-flow: row wrap;
        margin: 0;
      }
      @media (max-width: 425px) {
        
      }
`;

const PARAGRAPH = styled.div`
  line-height: 100%;
  font-size: 16px;
  font-family: var(--unnamed-font-family-proxima-nova);

`;

const MessageField = styled(TextArea)`
  ${PARAGRAPH}
  color: ${props => props.dark ? '#ffffff' : '#707070'};
  padding-left: 0px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 5px;
  width: 100%;
  border: solid thin #707070;
  font: normal normal normal 20px/28px var(--unnamed-font-family-brandon-grotesque);

  ::placeholder {
    color: ${props => props.dark ? '#ffffff' : '#707070'};
  }
`;

const TextField = styled(Input)`
  ${PARAGRAPH}
  color: ${props => props.dark ? '#ffffff' : '#707070'};
  padding-left: 0px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 5px;
  width: 100%;
  margin-bottom: 10px;
  border: solid thin #707070;
  font: normal normal normal 20px/28px var(--unnamed-font-family-brandon-grotesque);
  
  ::placeholder {
    color: ${props => props.dark ? '#ffffff' : '#707070'};
  }
  
`;

export {
    ContactBtn,
    ContactView,
    ContactTitle,
    Even,
    Message,
    InfoBox,
    InfoCol,
    Info,
    MessageField,
    TextField,
}