import React, {forwardRef, useState} from "react";
import { useMediaQuery } from 'react-responsive';
import styled from "styled-components";
import {ContactTitle, ContactView, Even, Info,
    TextField,
    MessageField,
    } from "../styles/contactFormStyles"

const Contact = (props, ref) => {
    const { dark } = props; 
    const isMobile = useMediaQuery({ maxWidth: "768px" });

    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [image, setImage] = React.useState(null); //See Supporting Documentation #1
    const imageInputRef = React.useRef(); //See Supporting Documentation #2


  
    const url = `${process.env.GATSBY_API_URL}/webform_rest/submit`;
  
    const submitForm = async (event) => {
      event.preventDefault();
  
      const contactDetail = {
        webform_id: process.env.GATSBY_CONTACT_FORM_ID,
        name: userName,
        email: userEmail,
        file: image,
      };
      const result = await fetch(url, {
        body: JSON.stringify(contactDetail),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
  
      if (!result.ok) {
        // setSuccessMessage('');
        console.log("Something went wrong!");
      } else {
        // setSuccessMessage('Mesajınız alındı. Teşekkür ederiz.');
        console.log("Message Submitted Successfully");
        setUserName("");
        setUserEmail("");
        imageInputRef.current.value = "";//Resets the file name of the file input - See #2
        setImage(null); //Resets the value of the file input - See #1
      }
    };  
  
    return(
    <ContactView {...props} ref={ref} id="contact">
        <StyledForm onSubmit={submitForm}>
            <TextField
              placeholder="Name"
              bordered={false}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
            <TextField
              placeholder="Email"
              bordered={false}
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
            <Upload 
            style={{textAlignLast: "center"}}
            type="file"
            onChange={event => setImage(event.target.files[0])} //See Supporting Doc #3
            ref={imageInputRef} //Apply the ref to the input, now it's controlled - See #2
            />
            <FormSubmit type="submit" onClick={submitForm}>
                Submit
              </FormSubmit>
          </StyledForm>
    </ContactView>
    )
}

export default forwardRef(Contact);

const StyledForm = styled.form`
display: flex;
flex-flow: column;
  width: 50%;
  @media (max-width: 768px) {
      width: 50%;
      margin: 2%;
    }
`;

const Upload = styled.input`
width: -webkit-fill-available;
margin-bottom: 10px;
text-align-last: center;
`;

const FormSubmit = styled.button`
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #707070;
  border: none;
  color: #ffff;
  box-sizing: border-box;
  text-decoration: none;
  margin-right: 0px;
  font: normal normal normal 22px/30px var(--unnamed-font-family-brandon-grotesque);
  padding: 10px;
  cursor: pointer;
  width: 100%;
  &&:hover,
  &&:focus {
    background-color: #292929;
    color: #ffff;
  }
`;
