import * as React from "react"
import {Container, Wrapper } from "../styles/eventStyles"
import { View, Title, Subtitle, Para, Goto} from "../styles/homeStyles"
import {Pic, Blocks, Block, Details, Label, SponsorGoTo, List, ListItem} from "../styles/contestStyles"
import ContestImg from "../images/art-contest2.png"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubForm from "../components/submissionForm"
//import "../styles/animation.css"

export default class Contest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          submitted: false
        }
      }
    
      render() {
        return (
  <Layout>
      
    <Seo title="Events" />
    <Container>
        <Wrapper style={{width: "100%"}}>
            <View style={{height: "40vh"}}>
                <Title>YOUTH CONTEST</Title>
                <Subtitle>Details</Subtitle>
            </View>
            <Details style={{backgroundImage: `url(${ContestImg})`}}>
                <Blocks>
                    <Block>Any Medium</Block>
                    <Block>Grades 6-12</Block>
                    <Block>Great Prizes</Block>
                </Blocks>
            </Details>
            <Para style={{marginTop: "75px", marginBottom: "75px"}}>Inland Arts is looking for submissions from young artists in grades 6-12. Participants are all eligible for awesome perks, and the winners will get access to amazing prizes and opportunities! The contest and associated events will also be a part of our documentary film that is currently in production about the arts community in Spokane. This is your chance to be a part of something bigger that will bring attention to the arts and help it to continue growing and making an impact on our community!</Para>
            <Details style={{marginTop: "5%"}}>
                <Title>Cureently Available Prizes</Title>
                {/*MacBook Pro, Ipad Pro, Calb Mural With pro Artist*/}
                <List>
                    <ListItem>MacBook Pro</ListItem>
                    <ListItem>Ipad Pro</ListItem>
                    <ListItem>Calaboration Mural with a Professional Artist</ListItem>
                </List>
                {/*<Para style={{marginTop: "25px", marginBottom: "40px"}}>Our partnerships with local businesses, schools and organizations allows us to provide an awesome prize package for our participants and winners. First of all, you will have the opportunity to appear in our community centered documentary film about the arts. In addition, look below to see all the awesome stuff we're giving out for those who enter.</Para>*/}
                <Label>Participants</Label>
                <Para style={{marginTop: "10px", marginBottom: "40px"}}>Participants will have the opportunity to attend a pro-am arts event, where they will be paired with a professional artist for several hours and be able to work along-side with and learn from the artist. It's all about encouraging the next generation of people who will continue building our culture and sharing it with the community.</Para>
                <Label>Winners</Label>
                <Para style={{marginTop: "10px", marginBottom: "40px"}}>Participants will have the opportunity to attend a pro-am arts event, where they will be paired with a professional artist for several hours and be able to work along-side with and learn from the artist. It's all about encouraging the next generation of people who will continue building our culture and sharing it with the community.</Para>
            </Details>
            <Details  style={{marginTop: "25px", marginBottom: "25px"}}>
                <Title>SUBMISSION</Title>
                <Para style={{marginTop: "25px", marginBottom: "25px"}}>Use the form below to submit your artwork. All submissions are due by <b>September 1st, 2022</b>. You will be automatically subscribed to our mailing list for updates on contest prizes, events and winner announcements.</Para>
                <SubForm  data={this.props.data} />
            </Details>
            <Details>
                <Title>BECOME A SPONSER</Title>
                <Para style={{marginTop: "25px", marginBottom: "25px"}}>We need help to make this event a positive and fruitful experience for everyone! We plan to release an ambitious prize package that will help winners and runners-up to further explore a career in art and help them take that next step. In order to do that, we’re looking for sponsors to take part in the event and contribute toward the prizes. Learn how you can help and what we can do for you in return by looking at the page linked below!</Para>
                <div style={{width: "100%"}}>
                <SponsorGoTo to="/sponsor/">Become a sponsor</SponsorGoTo>
                </div>
            </Details>
        </Wrapper>
    </Container>
  </Layout>
        );
    };
};

